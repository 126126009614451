<template>
  <div>
    <ServicesTopBar />
    <Banner />
    <PageDetails />
    <SiteFooter />
  </div>
</template>

<script>
import Banner from '../components/page/Banner.vue'
import PageDetails from '../components/page/PageDetails.vue'
import ServicesTopBar from '../components/services/ServicesTopBar.vue'
import SiteFooter from '../components/site/SiteFooter.vue'

export default {
  components: {
    ServicesTopBar, SiteFooter, Banner, PageDetails,
  },
  data() {
    return {

    }
  },
  methods: {
    getImage(image) {
      return `background: url(${image})`
    },
  },
}
</script>

<style scoped>

</style>
